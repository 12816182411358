import { Model } from "@tailflow/laravel-orion/lib/model"

export class Document extends Model<{
  name: string
  description: string
}> {
  public $resource(): string {
    return "documents"
  }

  // Declare $create as a static method if needed for type safety
  static $create(): Document {
    return new Document()
  }
}
