/**
 * These are configuration settings for the production environment.
 *
 * Do not include API secrets in this file or anywhere in your JS.
 *
 * https://reactnative.dev/docs/security#storing-sensitive-info
 */
const API_URL = "https://api-stage.templagon.com"
const API_PATH = "api/v1"

export default {
  API_URL,
  API_PATH,

  get API_ROOT() {
    return `${API_URL}/${API_PATH}`
  },
  get API_LOGIN_ROUTE() {
    return `${API_URL}/${API_PATH}/token`
  },
  get API_SIGNUP_ROUTE() {
    return `${API_URL}/${API_PATH}/register`
  },
}
