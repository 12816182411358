import React, { FC } from "react"
import { observer } from "mobx-react-lite"
import { HomeStackScreenProps } from "app/navigators"
import { Document } from "app/models/Document"
import { useEffect, useState } from "react"
import { DndContext } from "@dnd-kit/core"

import { StyleSheet } from "react-native"

interface DashboardScreenProps extends HomeStackScreenProps<"dashboard"> {}

export const DashboardScreen: FC<DashboardScreenProps> = observer(function DashboardScreen({
  navigation,
}) {
  const [documents, setDocuments] = useState<Document[]>([])
  const [loading, setLoading] = useState(true)

  const [rows, setRows] = useState<string[]>(["something", "xyz", "abc123"])

  useEffect(() => {
    async function fetchDocuments() {
      try {
        const fetchedDocuments = await Document.$query().get()
        setDocuments(fetchedDocuments)
        setLoading(false)
      } catch (error) {
        console.error("Error fetching documents:", error)
      }
    }

    fetchDocuments()

    navigation.setOptions({
      title: "Dashboard",
    })
  }, [])

  //handleDragEnd
  function handleDragEnd(event) {}

  return (
    <div>
      <h1 style={styles.h1}>HTML Test</h1>
      {rows.map((row, index) => (
        <div style={styles.test123}>
          <p>
            <b>{row}</b>
          </p>
          <div style={styles.delbtn} onClick={() => setRows(rows.filter((r) => r !== row))}>
            Delete
          </div>
          <div onClick={() => alert("!!")}>Alertr</div>
        </div>
      ))}
      <DndContext onDragEnd={handleDragEnd}>asd</DndContext>
    </div>
  )
})

const styles = StyleSheet.create({
  test123: {
    backgroundColor: "#fff",
    padding: 10,
    borderRadius: 10,
    marginBottom: 10,
    margin: 30,
    textAlign: "center",
    fontSize: 30,
  },
  delbtn: {
    backgroundColor: "#f00",
    padding: 10,
    borderRadius: 10,
    marginBottom: 10,
    margin: 30,
    textAlign: "center",
    color: "#fff",
    cursor: "pointer",
    fontSize: 15,
  },
  h1: {
    textAlign: "center",
  },
})
